<template>
  <div class="orderservice">
    <div class="orderinfo">
      <van-card
        v-for="(item, index) in order.goods"
        :key="index"
        :desc="'SKU：'+item.optionname"
        :title="'产品：'+item.goodstitle"
        :thumb="item.goodsthumb"
      >
        <template #tag>
          <van-tag v-if="order.status == 1" mark type="danger">{{order.is_outorder_str}}</van-tag>
          <van-tag v-if="order.status > 1" mark type="danger">{{order.status_str}}</van-tag>
        </template>
      </van-card>
      <div class="order_row">订单号：{{order.ordersn}} <van-tag @click="copy(order.ordersn)" style="margin-left: 10px;">复制</van-tag></div>
      <div class="order_row">收货方：{{order.consignee}}</div>
      <div class="order_row" v-if="order.expresssn" @click="getexpress(order.expresssn)">
        <div class="text_chaochu_yincang">快递号：{{order.expresssn}}</div>
        <van-tag style="margin-right: 20px;margin-left: 10px;">查询</van-tag>
        <van-tag @click="copy(order.expresssn)">复制</van-tag>
      </div>
      <div class="order_row">下单时间：{{order.createtime_str}}</div>
      <div class="order_row" v-if="order.rtype == 0" style="color: #CE0414;">退款金额：￥{{order.rprice}}</div>
    </div>
    <div class="caozuo_mian">
      <van-field border v-model="content" type="textarea" rows="3" :placeholder="order.content_tip ? order.content_tip : '请输入售后内容'" />

      <van-cell title="上传图片" class="upload">
        <van-uploader v-model="fileList" :after-read="afterRead" @delete="delimg" />
      </van-cell>

      <div class="btn_main">
        <van-button round color="#FDD736" size="small" v-if="order.button1_text && order.button1_text.length > 1"  @click="formSubmit(order.button1_val)">{{order.button1_text}}</van-button>
        <van-button round color="#FDD736" size="small" v-if="order.button2_text && !(order.button2_val == 89 && order.status < 0)"  @click="formSubmit(order.button2_val)">{{order.button2_text}}</van-button>
        <van-button round color="#FDD736" size="small" v-if="order.button3_text && !(order.button3_val == 89 && order.status < 0)"  @click="formSubmit(order.button3_val)">{{order.button3_text}}</van-button>
        <van-button round color="#FDD736" size="small" v-if="order.button4_text && !(order.button4_val == 89 && order.status < 0)"  @click="formSubmit(order.button4_val)">{{order.button4_text}}</van-button>
        <van-button round color="#FDD736" size="small" v-if="order.button5_text && !(order.button5_val == 2 && order.status < 0)"  @click="formSubmit(order.button5_val)">{{order.button5_text}}</van-button>
      </div>
    </div>
    <div class="history_list">
      <van-cell v-for="(item, index) in order.services" :key="index">
        <template #title>
          <div>
            <div style="display: flex; align-items: center;">
              <van-image
                v-if="item.wxinfo && item.wxinfo.avatarUrl && item.wxinfo.avatarUrl.length > 10"
                :src="item.wxinfo.avatarUrl"
                lazy-load
                round
                width="20"
                height="20"
                style="display: block; margin-right: 5px;"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div user-select>{{item.title}}</div>
            </div>
            <div>内容：{{item.content}}</div>
            <div class="img_list" v-if="(item.sh_img && item.sh_img.length > 0) || (item.video && item.video.length > 0)">
              <video 
                v-if="item.video && item.video.length > 1"
                :src="item.video" 
                :show-center-play-btn='true' 
                :show-play-btn="true" 
                controls
                style="width: 50px; height: 50px; float: left;margin: 0 10px 10px 0;"
              ></video>
              <van-image
                v-for="(val, key) in item.sh_img"
                :key="key"
                :src="val"
                lazy-load
                width="50"
                height="50"
                style="display: block; border-radius: 2px;"
                @click="yulan(key, item.sh_img)"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
            </div>
          </div>
        </template>
      </van-cell>
    </div>

    <!-- 物流信息弹框 -->
    <van-dialog
      use-slot
      title="物流信息"
      v-model="showexp"
      theme="round-button"
    >
      <div class="express_list" style="max-height: 300px;overflow-y: auto;overflow-x: hidden;" v-if="expressList && expressList.length > 0">
        <van-steps
          active="0"
          direction="vertical"
          active-color="#ee0a24"
        >
          <van-step v-for="(item, index) in expressList" :key="index">
            <div class="row1">{{item.text}}</div>
            <div class="row2">{{item.desc}}</div>
          </van-step>
        </van-steps>
      </div>
      <div class="express_list" v-if="!expressList || expressList.length <= 0">
        <van-empty description="暂无快递信息" />
      </div>
    </van-dialog>

    <van-dialog
      use-slot
      title="请输入补偿金额"
      v-model="showbuchang"
      show-cancel-button
      @confirm="buchagnConfirm"
      @cancel="onClose"
    >
      <div class="buchangInput">
        <van-field
          v-model="content"
          placeholder="请输入补偿金额"
          input-align="center"
          :border="false"
          type="digit"
        />
      </div>
    </van-dialog>

    <van-dialog
      use-slot
      title="确认退款？"
      v-model="showtuikuan"
      show-cancel-button
      @confirm="tuikuanConfirm"
      @cancel="onCloseTK"
      message-align="center"
    >
      <div class="tuikuanmsg">
        确认退给用户<span style="font-size: 16px; font-weight: bold; color: #F10A24;">{{order.rprice}}</span>元？
      </div>
    </van-dialog>

    <van-dialog
      use-slot
      title="退款列表"
      v-model="showtuikuanlist"
      message-align="center"
    >
      <div class="tuikuanlist">
        <van-cell v-for="(item, index) in tklist" :key="index">
          <div slot="title" class="title">
            <div class="row">
              <div class="row_c1">退款编号：{{item.applyno}}</div>
            </div>
            <div class="row">
              <div class="row_c1" v-if="item.content && item.content != '--'">{{item.content}}</div>
            </div>
            <div class="row">
              <div class="row_c1 time">申请时间：{{item.create_time}}</div>
              <div>申请金额：￥{{item.re_ghs_price}}</div>
            </div>
            <div class="row">
              <div class="row_c1 time">
                {{item.pay_time && item.pay_time.length > 10 ? '打款时间：'+item.pay_time : ''}}
                {{item.reject_time && item.reject_time.length > 10 ? '驳回时间：'+item.reject_time : ''}}
              </div>
              <div class="success">{{item.status_desc}}</div>
            </div>
          </div>
        </van-cell>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { ImagePreview } from 'vant';
export default {
  name: "OrderService",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0, // 分享人id
      loading: false,
      finished: false,
      orderid: 2425623,
      order: {},
      list: [], // 展示内容
      option1: 0,
      shownodata: false,
      shopid: 0, // 供货商id
      content: '',
      fileList: [],
      imgs: [],
      
      iswx: false, // 是否在微信中
      code: null, // 页面中的code值
      getUnionid: false, // 是否需要获取微信unionid
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
        ], // 必填，需要使用的JS接口列表
        timestamp: 1585098244, // 必填，生成签名的时间戳
        nonceStr: "dfbfwkm1egc", // 必填，生成签名的随机串
        signature: "d8caa8a88d588a8dc5379dbfbde5d875886baed5", // 必填，签名
      },
      bcId: 0,
      shouhou: 0,
      wxinfo: {}, 
      showexp: false,
      showbuchang: false,
      showtuikuan: false,
      showtuikuanlist: false,
      expressList: [],
      tklist: [],
      t: 6,
    }
  },
  mounted() {
    this.shopid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.shareid = this.$route.query.shareid ? this.$route.query.shareid : 0;
    this.orderid = this.$route.query.id ? this.$route.query.id : 0;
    this.t = this.$route.query._t ? this.$route.query._t : 6;
    this.shouhou = localStorage.getItem("DK_SHOUHOU") ? parseInt(localStorage.getItem("DK_SHOUHOU")) : 0;
    this.wxinfo = localStorage.getItem("DK_GHWXINFO") ? JSON.parse(localStorage.getItem("DK_GHWXINFO")) : {};
    this.$parent.getmerchname(this.shopid, "售后处理");
    if (window.isWeixin()) {
      this.iswx = true;
    }
    if (!localStorage.getItem("DK_GHLOGIN") || localStorage.getItem("DK_GHLOGIN") != 'true') {
      this.$router.push({
        path: 'ghs_login?id='+this.orderid+'&merchid='+this.shopid+'&shareid='+this.shareid,
      });
    }else {
      this.init();
    }
    /*
    if (!this.iswx) {
      if (!localStorage.getItem("DK_GHLOGIN") || localStorage.getItem("DK_GHLOGIN") != 'true') {
        this.$router.push({
          path: 'ghs_login?id='+this.orderid+'&merchid='+this.shopid+'&shareid='+this.shareid,
        });
      }
      
    }else {
      if (!localStorage.getItem("DK_GHWXINFO")) {
        // 用户授权后获取code值
        this.code = this.$route.query.code ? this.$route.query.code : null;
        if (this.code) {
          this.getwxlog(this.code);
          return false;
        }else if (!localStorage.getItem("DK_GHWXINFO")) {
          this.getwxinfo(); // 自动获取用户信息
          return false;
        }
      }else{
        this.code = this.$route.query.code ? this.$route.query.code : null;
        if (this.code) {
          this.$router.replace({
            name: "OrderService",
            query: {
              id: this.orderid,
              merchid: this.shopid,
              shareid: this.shareid,
              _t: this.t
            }
          })
        }
      }
    }
    */

  },
  methods: {
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = this.wxinfo.openid ? this.wxinfo.openid : null;
      if (!window.isWeixin()) {
        openid = true; // 在H5页面中打开
        this.iswx = false;
      }

      /* 在微信中打开页面 */
      if (!openid || !this.wxinfo.avatar) {
        this.iswx = true;
        let wxAuthUrl = null;
        wxAuthUrl =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" +
          encodeURIComponent(window.location.href) +
          "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
        // 传到后台得到openid, 其中access_token 2小时过期
        if (_that.code == null || _that.code === "") {
          window.location.replace(wxAuthUrl); // [自刷新当前页]
        }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      const toast = this.$toast({
        message: '加载中...',
        type: 'loading',
        duration: 0
      })
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                toast.clear();
                
                if (res.data && res.data.headimgurl && res.data.openid == response.data) {
                  localStorage.setItem("DK_GHWXINFO", JSON.stringify(res.data));
                  // Dialog({message: JSON.stringify(res.data)})
                  _that.reloaderUrl();
                }
                
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    reloaderUrl() {
      let _that = null;
      _that = this;
      if (_that.code) {
        _that.$router.replace({
          name: "OrderService",
          query: {
            id: _that.orderid,
            merchid: _that.shopid,
            shareid: _that.shareid,
            _t: _that.t
          }
        })
      }
      _that.init();
    },

    init() {
      this.getOrder();
    },
    getOrder() {
      console.log("获取订单");
      this.getSearch();
    },

    getSearch() {
      let that = null
      that = this;
      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_GHUID") ? localStorage.getItem("DK_GHUID") : '';
      that.$axios
      .post(
        that.$store.state.domain + "shopsmobile/service/getInfo",
        that.$qs.stringify({
          id: that.orderid,
          merchid: that.shopid,
          "bc_id": that.bcId,
          shouhou: that.shouhou,
          _t: that.t
        })
      )
      .then(res => {
        console.log(res);
        if (res.data.code == 100000) {
          that.order = res.data.data;
        }
      })
      .catch(err => {
        console.log(err);
      })
    },

    // 复制信息
    copy(text) {
      console.log(text);
      let _that = null;
      _that = this;
      _that.$copyText(text).then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },

    // 读取文件并 上传
    afterRead(file, detail) {
      console.log(file, detail);
      let that = null;
      that = this;

      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/shouhou/');

      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      )
      .then(res => {
        if (res.data && res.data.code && res.data.code == 100000) {
          that.$toast(res.data.msg ? res.data.msg : "图片上传成功");
          that.imgs.push(res.data.img_url);
        }else {
          that.$toast(res.data.msg ? res.data.msg : "图片上传失败");
        }
      })
    },

    // 删除图片
    delimg(file, detail) {
      this.imgs.splice(detail.index, 1);
    },

    // 执行操作
    formSubmit(value) {
      console.log(value);
      if (value == 89) {
        this.showbuchang = true;
      }else{
        if (value == 1) {
          this.showtuikuan = true;
        }else{
          this.doFormSbumit(value);
        }
      }
    },

    // 请求数据
    doFormSbumit(value) {
      let that = null;
      that = this;
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_GHWXINFO") ? JSON.parse(localStorage.getItem("DK_GHWXINFO")) : {};

      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_GHUID") ? localStorage.getItem("DK_GHUID") : '';
      that.$axios
      .post(
        that.$store.state.domain + "shopsmobile/service/addInfo",
        that.$qs.stringify({
          content: that.content,
          "shi_img": that.imgs,
          video: '',
          id: that.orderid,
          "btn_val": value,
          merchid: that.shopid,
          "bc_id": that.bcId,
          openid: that.shouhou < 1 && wxinfo.openid && wxinfo.openid.length > 5 ? wxinfo.openid : '',
          unionid: that.shouhou < 1 && wxinfo.unionid && wxinfo.unionid.length > 5 ? wxinfo.unionid : '',
          nickName: that.shouhou < 1 && wxinfo.nickName ? wxinfo.nickName : '',
          avatarUrl: that.shouhou < 1 && wxinfo.avatarUrl ? wxinfo.avatarUrl : '',
          shouhou: that.shouhou,
          shareid: that.shareid
        })
      )
      .then(res => {
        console.log(res);
        that.$toast(res.data.msg ? res.data.msg : "")
        if (res.data.code == 100000) {
          that.order = res.data.data;
        }
      })
      .catch(err => {
        console.log(err);
      })
    },

    // 查询快递信息
    getexpress(sn) {
      console.log(sn);
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_GHUID") ? localStorage.getItem("DK_GHUID") : '';
      that.$axios
      .post(
        that.$store.state.domain + "shopsmobile/service/getExpress",
        that.$qs.stringify({
          expresssn: sn
        })
      )
      .then(res => {
        if (res.data && res.data.code == 100000) {
          that.expressList = res.data.data
          that.showexp = true;
        }else {
          that.$toast("暂无快递信息");
        }
      })
    },

    onClose() {
      this.showbuchang = false;
    },

    buchagnConfirm() {
      this.doFormSbumit(89);
      this.showbuchang = false;
    },

    onCloseTK() {
      this.showtuikuan = false;
    },

    /**
     * 确认退款弹框确认按钮
     */
    tuikuanConfirm() {
      this.onCloseTK();
      this.doFormSbumit(1);
    },

    // 预览图片
    yulan(i, arr) {
      if (arr.length > 0) {
        ImagePreview({
          images: arr,
          startPosition: i,
        });
      }
    },
  },
}
</script>
<style lang="less">
.orderservice {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: #f0f0f0;
  padding-bottom: 60px;
  .orderinfo {
    margin: 10px 10px 0;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    background-color: #fafafa;
    text-align: left;
    font-size: 12px;
    .van-card {
      margin-top: 0;
      padding: 0 10px 10px;
      &:first-of-type {
        padding-top: 10px;
      }
      .van-card__thumb {
        width: 60px;
        height: 60px;
      }
      .van-card__content {
        min-height: 60px;
        justify-content: center;
      }
    }
    .order_row {
      line-height: 24px;
      padding: 0 10px;
      display: flex;
      &:last-of-type {
        margin-bottom: 5px;
      }
    }
  }
  .caozuo_mian {
    margin: 0 10px 10px 10px;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    .van-field__value {
      flex: 1;
    }
    .upload .van-cell__value {
      flex: 1;
      text-align: right;
    }
    .btn_main {
      padding: 10px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      .van-button {
        padding: 0 10px;
        flex: auto;
        margin: 0 10px 10px 0;
        &:last-of-type {
          margin-right: 0;
        }
        .van-button__text {
          color: #333;
        }
      }
    }
  }
  .history_list {
    margin: 10px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #fff;
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
    .van-cell {
      &:first-of-type {
        color: #CE0414;
      }
    }
    .img_list {
      display: flex;
      flex: wrap;
      .van-image {
        margin-right: 10px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  /* 退款弹框 */
  .tuikuanmsg {
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
  }
  .express_list {
    .row1,.row2 {
      text-align: left;
    }
    .row1 {
      font-weight: bold;
    }
  }

  /*小额补偿*/
  .buchangInput {
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>